import { defineStore } from 'pinia'
import { getAllAttributes } from '@/api/attributes/getAllAttributes'
import { deleteAttribute } from '@/api/attributes/deleteAttribute'
import { createAttribute } from '@/api/attributes/createAttribute'
import { getAttributeOptions } from '@/api/attributes/options/getAttributeOptions'
import { deleteAttributeOption } from '@/api/attributes/options/deleteAttributeOption'
import type { Attribute } from '@/types/attribute'
import type { Option } from '@/types/option'
import type { QueryParameters } from '@/types'
import { useRouter } from 'vue-router'
import { handleError } from '@/helpers/handleError'
const router = useRouter()
interface AttributeStore {
  attributes: Attribute[]
  loading: boolean
  selectedAttributeId: number | null
  options: Option[]
  optionsLength: number
  currentAttribute: Attribute | null
}

export const useAttributesStore = defineStore('attributes', {
  state: (): AttributeStore => ({
    attributes: [],
    loading: true,
    selectedAttributeId: null,
    options: [],
    optionsLength: 0,
    currentAttribute: null
  }),

  actions: {
    async getAttributes() {
      this.loading = true
      const { data, success, error } = await getAllAttributes()
      if (success) {
        this.attributes = data
      } else {
        router.push(handleError(error))
      }
      this.loading = false
    },
    async deleteAttribute(id: number) {
      const { success, error } = await deleteAttribute(id)
      if (success) {
        this.attributes = this.attributes.filter((attribute: Attribute) => attribute.id !== id)
      }
      return {
        success,
        error
      }
    },
    async createAttribute(createData: { name: string; type: string }) {
      const { success, data } = await createAttribute(createData)

      if (!success) {
        return {
          success: false
        }
      }
      this.attributes.unshift(data)
      return {
        success: true
      }
    },
    async getAttributesOptions(options: QueryParameters) {
      this.loading = true
      const res = await getAttributeOptions(this.selectedAttributeId!, options)
      if (res.success) {
        this.options = res.data.options
        this.optionsLength = res.data.total_items
        this.loading = false
      }
    },
    async deleteAttributeOption(id: number, attribute_id: string) {
      const { success, error } = await deleteAttributeOption(id, attribute_id)
      if (success) {
        this.options = this.options.filter((option: Option) => option.id !== id)
      }

      return {
        success,
        error
      }
    },
    async updateAttributeOption(option_id: string | number, payload: any) {
      this.options = this.options.map((option: Option) => {
        if (option.id === Number(option_id)) {
          option = payload
        }
        return option
      })

      return {
        success: true,
        error: false
      }
    },
    setCurrentAttribute(attribute: Attribute) {
      this.currentAttribute = attribute
      this.selectedAttributeId = attribute.id
    },
    addAttributeOption(option: Option) {
      this.options.unshift(option)
    }
  }
})
